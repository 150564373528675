import React, { useState, useEffect } from 'react';
import { Box, Button, Input, Typography, Stack, Tooltip, Textarea, Snackbar } from '@mui/joy';
import { isMobile, } from 'react-device-detect';
import L from 'leaflet'
import {  Marker, useMapEvents, useMap } from 'react-leaflet'

import { createNewPin, fetchPinList } from './pinFetching';
import { PinCreationModal } from '../Modals/pinModals';
import { usePinListPosition } from '../MapUtils/MapContext/PinListContext';
import { usePosition } from '../MapUtils/MapContext/PositionContext';

import { BasicAssets } from '../../assets/basicAssets';
import focus1 from '../../assets/location/focus.png'
import '../../App.css'


const customIconOrange = new L.Icon({
  iconUrl: BasicAssets.orangeLocation,
  iconSize: [30, 30], // size of the icon
  iconAnchor: [15, 30], // point of the icon which will correspond to marker's location
});

const NewEntryModal = ({ open, handleCloseSnack, coordinatesInput, setDisableZoom, handleNewLocation }) => {
  
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [openCreate, setOpenCreate] = useState(false); // Control the modal with this state
  const [titleError, setTitleError] = useState('');
  const { addPin, setPinView  } = usePinListPosition();
  const { userID } = usePosition();
  const [ copied, setCopied] = useState(false)

  const handleCreateOpen = () => {
    setOpenCreate(true); // Opens the modal
    handleCloseSnack();  // Close Snackbar immediately
  };

  const handleCreateClose = () => {
    setOpenCreate(false); // Closes the modal
    handleNewLocation()
    setPinView(true)
    //setCoordinates({ lat: '', lng: '' });
    setTitle('');
    setDescription('');
    setDisableZoom(false)
  };

  const handlePinConfirm = async (event) => {
    setTitleError('');

    // Validate title field
    if (!title) {
      setTitleError('Title is required.');
      return; // Prevent form submission
    }
  
    try {
      // Call createNewPin and wait for the result
      const creatorID = userID.username? userID.username :userID.email
      
      console.log('Submitting new pin...');
      await createNewPin(coordinatesInput, title, description, creatorID);

      // Fetch the updated pin list after creating a new pin
      const { data } = await fetchPinList();
      console.log('Fetched pins', data.listEntries.items);

      // Iterate over the fetched pins and add them
      data.listEntries.items.forEach(({ lat, lng, title, description, id, creator }) => {
        addPin(lat, lng, title, description, id, creator);
      });

      // Reset the form fields or any other necessary state
      setOpenCreate(true);
      // Close the modal or show success message
      // handleClose();  // Assuming this closes the modal
      
    } catch (error) {
      console.error('Error creating pin:', error);
    }
  };

  //allow copying of coordinates in modal
  const handleCoordinatesCopy = () => {
        
    const textCopy = `${coordinatesInput.lat}, ${coordinatesInput.lng}`
    // Use the clipboard API to copy the text
    navigator.clipboard.writeText(textCopy)
      .then(() => {
        setCopied(true); // Optional: Set copied state to true if successful
        setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };
  

  return (
    <>
  {/* The card component will be displayed instead of Snackbar */}
  {(open || !openCreate) && (
    <Box
    onMouseEnter={() => setDisableZoom(true)}
    onMouseLeave={() => setDisableZoom(false)}
      sx={{
        position: 'fixed', // To make it appear like a modal
        bottom:'35px',
        //height:'300px',
        left: isMobile? '':'50px',
        minWidth: 350,
        width:isMobile? '95%': '',
        bgcolor: 'white',
        borderRadius: 5,
        border: '1px solid grey',
        boxShadow: 3,
        p: 1.5,
        zIndex: 1009, // Ensures the card appears on top of other content
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography level="h3" textAlign="center">
        Create New Pin
      </Typography>
      <Stack spacing={1} mt={0.5} width={'90%'}>
          <Stack spacing={0} direction="column" alignItems={'center'}>
            <Typography level='body-sm' fontWeight='bold'>Coordinates:</Typography>
            {coordinatesInput?
            <Tooltip title={`${coordinatesInput.lat}, ${coordinatesInput.lng}`} variant="solid" placement="top">
              <Typography onClick={handleCoordinatesCopy}>{`${coordinatesInput.lat.toFixed(4)}, ${coordinatesInput.lng.toFixed(4)}`}</Typography>
            </Tooltip>
            :
            <Typography>Select Coordinate</Typography>
            }
          </Stack>
        
        <Input
          label="Title"
          margin="normal"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required 
        />
        {titleError && <Typography style={{ color: 'red', fontSize:'12px', marginTop:0 }}>{titleError}</Typography>}
        <Textarea
          label="Description"
          multiline
          minRows={3}
          maxRows={6}
          margin="normal"
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Stack>

      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button variant="outlined" color="neutral" onClick={handlePinConfirm} style={{ marginLeft: 10 }}>
          Confirm
        </Button>
        <Button variant="outlined" color="neutral" onClick={handleCloseSnack} style={{ marginLeft: 10 }}>
          Cancel
        </Button>
      </Box>
    </Box>
  )}
  <Snackbar
  size='sm'open={copied}
  autoHideDuration={3000}
  anchorOrigin={{vertical:'bottom', horizontal:'left'}}
  sx={{
    maxWidth:'20px',
    marginBottom:'70px',
    marginLeft:100
    }}
    >Coordinates Copied!</Snackbar>

{openCreate && (
        <PinCreationModal handleCreateClose={handleCreateClose}  openCreate={openCreate} />
      )}
    </>
  );
};


const EntryClick = ({setDisableZoom, handleNewLocation, selectedPosition, setSelectedPosition, openCreateModal, setOpenCreateModal}) => {

  const {setActiveIcon,} = usePosition()

  useMapEvents({
    moveend: (e) => {
      if (isMobile) {
        const map = e.target;
        const center = map.getCenter();
        const pixelPoint = map.project(center); // Get the pixel coordinates of the center
        const adjustedPixelPoint = pixelPoint.subtract([0, 150]); // Adjust the y-axis by the desired offset
        const adjustedLatLng = map.unproject(adjustedPixelPoint); // Convert back to LatLng
  
        console.log({ adjustedLatLng });
        setSelectedPosition(adjustedLatLng); // Set the adjusted position
      }
    },
    click: (e) => {
      if (!isMobile) {
        const clickedPosition = e.latlng;
        console.log('Clicked coordinates:', clickedPosition);
  
        setSelectedPosition(clickedPosition);
      }
    }
  });

    const handleCloseSnack= () => {
      setSelectedPosition()
      setOpenCreateModal(false)
      setDisableZoom(false)
      setActiveIcon({
        newPin: false,
        search: false, 
        getInTouch:false,
      })
    }

    return (
      <>
                {selectedPosition/*.lat*/ !=undefined  && (
            <Marker onClick={()=>console.log('hello')} position={[selectedPosition.lat, selectedPosition.lng]} icon={customIconOrange} />
        )}
          <NewEntryModal coordinatesInput={selectedPosition} open={openCreateModal} setSelectedPosition={setSelectedPosition} handleCloseSnack={handleCloseSnack} setDisableZoom={setDisableZoom} handleNewLocation={handleNewLocation} openCreateModal={openCreateModal} setOpenCreateModal={setOpenCreateModal}/>

      </>
    )
}

const PinCreationToggle = ({setDisableZoom, disableZoom}) => {
  const [selectedPosition, setSelectedPosition] = useState()
  const [cursorChange, setCursorChange] = useState(false)
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const { setPinView, pinListPositions, addPin, removePin  } = usePinListPosition();
  const {  activeIcon, setActiveIcon, setPinListOpen } = usePosition()


  const handleFetchPinList= async() => {
    try {
        console.log('fetching pin list')
        const {data} = await fetchPinList()
        //console.log('fetched pins',data.listEntries.items)
        data.listEntries.items.forEach(({ lat, lng, title, description, id, creator }) => {
          addPin(lat, lng, title, description, id, creator);
        });
        console.log('refresh done')
        
    } catch (error) {
        console.log('error fetching pins', error)
    }
}

const refreshPinList = () => {
  if (pinListPositions.length > 0) {
    console.log('refresh start')
    pinListPositions.forEach(({title})=>{
      removePin(title)
    })
    
    handleFetchPinList()
  } else {
    handleFetchPinList()
  }
}

useEffect(() => {
  if (!activeIcon.newPin) {
    setSelectedPosition(null);
  }
}, [activeIcon.newPin]);

  const handleNewLocation = () =>{

    if (activeIcon.newPin) {
      setActiveIcon({
        newPin: false,
        search: false, 
        getInTouch:false,
      })
      setPinView(false)
      setPinListOpen(false)
      setOpenCreateModal(false)
    };
    if (!activeIcon.newPin) {
      setActiveIcon({
        newPin: true,
        search: false, 
        getInTouch:false,
      })
      setPinView(true)
      if (!isMobile) {
        setPinListOpen(true)

      }
      setOpenCreateModal(true)
    };
    if (isMobile) {
      setDisableZoom(false)
    }
    refreshPinList();
  }
  const map = useMap();
  useEffect(() => {
    // Change cursor on the body and the map container
    if (activeIcon.newPin && disableZoom=== false ) {

      map.getContainer().style.cursor = 'crosshair'; // Change cursor on the map
    } else {
      document.body.style.cursor = ''; // Revert to default when not placing pins
      map.getContainer().style.cursor = ''; // Revert map cursor as well
    }

    // Clean up: reset the cursor when component unmounts or newLocation changes
    return () => {
      document.body.style.cursor = ''; 
      map.getContainer().style.cursor = '';
    };
  }, [activeIcon.newPin, disableZoom, map]);

  useMapEvents({
    click: (e) => {
      if (isMobile) {
        const map = e.target;
        const center = map.getCenter();
        const pixelPoint = map.project(center); // Get the pixel coordinates of the center
        const adjustedPixelPoint = pixelPoint.subtract([0, 150]); // Adjust the y-axis by the desired offset
        const adjustedLatLng = map.unproject(adjustedPixelPoint); // Convert back to LatLng
  
        console.log({ adjustedLatLng });
        setSelectedPosition(adjustedLatLng); // Set the adjusted position
      }
    },
  });

  const handleMouseEnter = () => {
    setDisableZoom(true);
    setCursorChange(true)
  };
  const handleMouseLeave = () => {
    setDisableZoom(false);
    setCursorChange(true)
  };

  return (
    <>
    <Box
    onMouseEnter={handleMouseEnter} 
    onMouseLeave={handleMouseLeave} 
    >

    <Tooltip title={activeIcon.newPin ?'': isMobile?'Add Pin':'User Pins'} variant='solid' placement='right'>

    <Button  
  variant='solid' 
  sx={{
    position: 'fixed', 
    top: '150px', 
    left: '10px', 
    zIndex: 1006, 
    width: '30px', 
    height: '30px', 
    marginTop: '10px', 
    border: '1px solid grey',
    backgroundColor: 'white', 
    '&:hover': { 
      backgroundColor: 'var(--grey-hover)' 
    }
  }} 
  onClick={handleNewLocation}
>
  <img src={activeIcon.newPin ? BasicAssets.close : BasicAssets.addLocation} style={{ height: '15px' }}/>
</Button>
        </Tooltip>

    </Box>
    {activeIcon.newPin
    ? 
    <>
    <EntryClick setDisableZoom={setDisableZoom} handleNewLocation={handleNewLocation} selectedPosition={selectedPosition} setSelectedPosition={setSelectedPosition} openCreateModal={openCreateModal} setOpenCreateModal={setOpenCreateModal}/>
    </>

      :
      <></>
    }
    {isMobile
    ? 
    <>
    <img className='crosshair' src={focus1}/>
    </>
    :
    <>
    </>
    }
      </>
  )
}


export {NewEntryModal, EntryClick, PinCreationToggle};