import contact from './mail(1).png'
import support from './hand.png'
import maps from './trifold.png'
import pin from './pin(1).png'
import admin from './security.png'
import ullrLogo from '../ULLRLogo-small.png'
import leftChevron from './leftchevron.png'
import rightChevron from './rightchevron.png'

const NavImages = {
    contact:contact,
    support:support,
    maps:maps,
    pin:pin,
    admin:admin,
    ullrLogo:ullrLogo,
    leftChevron:leftChevron,
    rightChevron:rightChevron,
}

export {NavImages}