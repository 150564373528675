
const version = '1.0.7'
const preVersion = 'Beta'

/*
- fixed user location issue
- added user pin list to add pin toggle
- changing of font and weights thorughout app
- updated map data 
- updated support and about windows
*/
export {version, preVersion}