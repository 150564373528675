import React,{ useState, useEffect } from 'react';
import { ThemeProvider, Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {components, myTheme, FormFields} from './utils/customLoginScreen.js'

import { CssBaseline, extendTheme, Card, Typography } from '@mui/joy';
import {ThemeProvider as JoyUIThemeProvider} from '@mui/joy'

import { useLocation, useNavigate, Route, Routes } from 'react-router-dom';
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';

import Box from '@mui/joy/Box';


import NavBar from './components/Navbar.js';
import {BlankMapBody, BlackcombMapBody, SpearheadMapBody, WhistlerMapBody,WB512300, WB512450, WB1024300, WB1024450,} from './components/mapBody.js';
import { UpdateNameModalIMPORTANT, WaiverModalIMPORTANT, SpearheadWarningModal } from './utils/Modals/infoUpdateModals.js';
import Footer from './utils/Footer.js'
import SplashPage from './components/SplashPage.js';
import AdminAccess from './components/pages/AdminAccess.js';
import AboutPage from './components/pages/AboutPage.js';
import DonationsPage from './components/pages/DonationsPage.js';
import PrintPurchasePage from './components/pages/PrintPurchasePage.js';
import ContactPage from './components/pages/ContactPage.js';

import { usePosition } from './utils/MapUtils/MapContext/PositionContext.js';
import UpdateNotification from './utils/Modals/updateNotification.js';

import './App.css';

const theme = extendTheme({
  fontFamily: {
    display:'roboto',
    body:'roboto',
  },
});

function App() {

  const [showSplash, setShowSplash] = useState(true)
  const [updateUserName, setUpdateUserName] = useState(false)
  const [signWaiver, setSignWaiver]= useState(false)
  const [spearheadWarning, setSpearheadWarning] = useState(false)
  const [currentMap, setCurrentMap] = useState('wb512300')
  const { setPosition, currentUserRoles, setCurrentUserRoles, setUserID } = usePosition();

  // control for spearhead modal display

  const handleSpearheadWarningClose=()=> {
    setSpearheadWarning(false)
  }

  useEffect(()=>{
    if (currentMap === 'spearhead') {
      setSpearheadWarning(true)
    }
  }, [currentMap])
  
  //display modal for userName update
  const handleUpdateNameIMPORTANTClose = () =>{
    setUpdateUserName(false)
  }

  const handleSignWaiverClose = () => {
    setSignWaiver(false)
  }

  // sets current map
  const handleSetCurrentMap = (map) => {
    setCurrentMap(map)
    setPosition({ latitude: null, longitude: null, zoom: null })
  }

  const location = useLocation();
  const navigate = useNavigate()

  //=============================below is splashscreen logic========================
  //if location changes to base, will show the splashscreen
  useEffect(() => {
    // Check if the path is the homepage
    const isHomepage = location.pathname === '/';
  
    // If it's the homepage, show the SplashPage
    if (isHomepage) {
      setShowSplash(true);
    } else {
      setShowSplash(false);
    }
  }, [location.pathname]);

  // below sets the splashpage to end its displya
  const handleSplashEnd = () => {
    setShowSplash(false);
    navigate('/home')

  };

  // below for determining admin access
  const handleUserLoad = async () => {
    try {
      const session = await fetchAuthSession();
      //console.log('session')
      //console.log(session.tokens)
      setCurrentUserRoles(session.tokens.idToken.payload["cognito:groups"])
      console.log('Current user array:', session.tokens.idToken.payload["cognito:groups"]);
      //console.log("cognito groups", session.tokens.idToken.payload["cognito:groups"])

      
  } catch(error) {
    console.log('error retrieving user data')
    console.log(error)
  }
  try {
    const userAttributes = await fetchUserAttributes();
    console.log('fetching userAttributes')
    if (!userAttributes.preferred_username) {
      console.log('adjust username')
      setUpdateUserName(true)
    } else {
      setUserID({
        email:userAttributes.email,
        username:userAttributes.preferred_username,
      })
    }
    if (!userAttributes.zoneinfo) { 
      console.log('sign Waiver')
      setSignWaiver(true)
    }
  } catch(error) {
    console.log('error fetching user attributes')
    console.log(error)
  }
}

useEffect(() => {
    handleUserLoad()
    //setSignWaiver(true)
  }, [])


  return (

    <>
    <JoyUIThemeProvider theme={theme}>
    <CssBaseline /> {/* Apply global styles including the font */}
    {showSplash && <SplashPage onSplashEnd={handleSplashEnd} />}
    {!showSplash && (
      <ThemeProvider theme={myTheme}>
                  {/* Information Card with Text */}
    <Authenticator components={components} className='authenticator-background' formFields={FormFields} >
      <Box className={location.pathname === '/login' ? 'login-background' : ''}>

        <NavBar handleSetCurrentMap={handleSetCurrentMap} currentMap={currentMap} setCurrentMap={setCurrentMap}/>

        <UpdateNotification/>
        <UpdateNameModalIMPORTANT open={updateUserName} handleClose={handleUpdateNameIMPORTANTClose}/>
        <WaiverModalIMPORTANT open={signWaiver} handleClose={handleSignWaiverClose}/>
        <SpearheadWarningModal open={spearheadWarning} handleClose={handleSpearheadWarningClose}/>
        {location.pathname === '/home'
          &&
        currentMap === 'blankmap' ? <BlankMapBody handleSetCurrentMap={handleSetCurrentMap}/>:
        currentMap === 'blackcomb' ? <BlackcombMapBody />:
        currentMap === 'spearhead' ? <SpearheadMapBody/>:
        currentMap === 'whistler' ? <WhistlerMapBody/>:
        currentMap === 'wbcombo' ? <WB512300/>:
        currentMap === 'wb512300' ? <WB512300/>:
        currentMap === 'wb512450' ? <WB512450/>:
        currentMap === 'wb1024300' ? <WB1024300/>:
        currentMap === 'wb1024450' ? <WB1024450/>:
        currentMap === 'Admin'? <AdminAccess handleSetCurrentMap={handleSetCurrentMap}/> :
        currentMap === 'About' ?<AboutPage handleSetCurrentMap={handleSetCurrentMap}/>:
        currentMap === 'Support ULLR' ?<DonationsPage handleSetCurrentMap={handleSetCurrentMap}/>:
        currentMap === 'Purchase Prints' ?<PrintPurchasePage handleSetCurrentMap={handleSetCurrentMap}/>:
        currentMap === 'Get in Touch' ?<ContactPage handleSetCurrentMap={handleSetCurrentMap}/>:
        <BlankMapBody handleSetCurrentMap={handleSetCurrentMap}/>
      }
        <Footer/>
      </Box>

      <Routes>
        <Route path='/' element={showSplash && <SplashPage onSplashEnd={handleSplashEnd} />}/>
        <Route path ='/home' element ={<></>}/>

      </Routes>
    </Authenticator>
    </ThemeProvider>
    )}
    </JoyUIThemeProvider>
    </>
  );
}

export default App;
