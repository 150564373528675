import React, { useRef, useState, useEffect } from 'react';

import {  Marker, useMap } from 'react-leaflet'
import {Tooltip as TooltipLeaflet} from 'react-leaflet';
import L from 'leaflet';
import {  Button, Stack, Tooltip, Snackbar, Typography  } from '@mui/joy';
import {  isBrowser,} from 'react-device-detect';

import { usePosition } from '../MapContext/PositionContext';
import { usePinListPosition } from '../MapContext/PinListContext';
import { PinSummaryModal } from '../../Modals/pinModals';

import redLocation from '../../../assets/red-location.png'
import orangeLocation from '../../../assets/orange-location.png'
import cyanLocation from '../../../assets/cyan-location.png'
import off from '../../../assets/location/off.png'
import alert from '../../../assets/alert.png'
import 'leaflet/dist/leaflet.css';
import 'leaflet-gps/src/leaflet-gps.css';
import '../../../App.css'

const MapControl = ({ setMap }) => {
  const map = useMap();
  setMap(map); // Pass map instance to parent component
  return null;
};

const CenterOnPositionFunction = () => {
    const map = useMap();
    const { position } = usePosition();
    
    // Store the initial position of the map
    const initialPositionRef = useRef(map.getCenter());
  
    useEffect(() => {
      if (position.latitude && position.longitude) {
        // Set the view to the new position
        map.setView([position.latitude, position.longitude], position.zoom || map.getZoom());
        if (isBrowser) {
        // Calculate the offset needed to pan to the right by 25% of the viewport width
        const panOffset = [map.getSize().x * -0.25, 0];
        const point = map.latLngToContainerPoint([position.latitude, position.longitude]);
        const newPoint = [point.x + panOffset[0], point.y + panOffset[1]];
  
        // Convert the new point back to lat/lng and pan the map
        const newLatLng = map.containerPointToLatLng(newPoint);
        map.panTo(newLatLng);
        initialPositionRef.current = newLatLng;
  }
        // Update the initial position reference to the new position
      }
    }, [position, map]);
  
    return null;
  };


  const CenterOnUserPositionFunction = () => {
    const map = useMap();
    const { userPosition } = usePosition();
    
    // Store the initial position of the map
    const initialPositionRef = useRef(map.getCenter());
  
    useEffect(() => {
      if (userPosition.latitude && userPosition.longitude) {
        // Set the view to the new position
        map.setView([userPosition.latitude, userPosition.longitude], userPosition.zoom || map.getZoom());
        if (isBrowser) {
        // Calculate the offset needed to pan to the right by 25% of the viewport width
        const panOffset = [map.getSize().x * -0.25, 0];
        const point = map.latLngToContainerPoint([userPosition.latitude, userPosition.longitude]);
        const newPoint = [point.x + panOffset[0], point.y + panOffset[1]];
  
        // Convert the new point back to lat/lng and pan the map
        const newLatLng = map.containerPointToLatLng(newPoint);
        map.panTo(newLatLng);
        initialPositionRef.current = newLatLng;
  }
        // Update the initial position reference to the new position
      }
    }, [userPosition, map]);
  
    return null;
  };

  const isWithinBounds = (latlng, bounds) => {
    console.log({bounds})
    if (!bounds) {
      return false; // or false, depending on your use case if bounds are undefined
    }
    //coordinates for testing below
    /*
    const latlng = {coords : {

      latitude: 50.07036564463608,
      longitude: -122.94405903960804
    }
  
    }
    */
    
    const [southWest, northEast] = bounds;

  return (
    latlng.coords.latitude >= southWest[0] && // Latitude is greater than or equal to South-West latitude
    latlng.coords.latitude <= northEast[0] && // Latitude is less than or equal to North-East latitude
    latlng.coords.longitude >= southWest[1] && // Longitude is greater than or equal to South-West longitude
    latlng.coords.longitude <= northEast[1]    // Longitude is less than or equal to North-East longitude
  );
};

  function GPSLocatorComponent({ maxBounds, handleOutOfBounds, setDisableZoom }) {
  
    const [activeGPS, setActiveGPS] = useState(false);
    const [loading, setLoading] = useState(false); 
    const [accessError, setAccessError] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const { userPosition, setUserPosition} = usePosition()
  
    /*
    const handleMouseEnter = () => {
      setDisableZoom(true);
    };
    const handleMouseLeave = () => {
      setDisableZoom(false);
    };
    */

    const handleSnackClose = () => {
      setAccessError(false)
      setActiveGPS(false)
    }
  
    const map = useMap();
  
    const handleGPSActivate = () => {
      setActiveGPS(prev => !prev);
      setLoading(true);
      console.log('loading')
      setInitialLoad(true);
    }  
  
    useEffect(() => {
      let watchID
      if (!activeGPS) return;
    
      const updateUserPosition = async () => {
        try {
          if ("permissions" in navigator) {
            const permissionStatus = await navigator.permissions.query({ name: "geolocation" });
    
            if (permissionStatus.state === "denied") {
              console.error("Location permission is denied. Enable location in browser settings.");
              setAccessError(true);
              setLoading(false);
              setActiveGPS(false);
              return;
            }
    
            if (permissionStatus.state === "prompt" || permissionStatus.state === "granted") {
              if ("geolocation" in navigator) {
                
    
                watchID = navigator.geolocation.watchPosition(
                  //position handling function
                  (position) => {
                    setLoading(true);
                    console.log("Position obtained:", position);
    
                    const newPosition = {
                      latitude: position.coords.latitude,
                      longitude: position.coords.longitude,
                      elevation: position.coords.altitude,
                    };
    
                    if (isWithinBounds(position, maxBounds)) {
                      if (
                        newPosition.latitude !== userPosition.latitude ||
                        newPosition.longitude !== userPosition.longitude
                      ) {
                        setUserPosition(newPosition);
                      }
    
                      if (initialLoad) {
                        if (newPosition.latitude && newPosition.longitude) {
                          map.setView([newPosition.latitude, newPosition.longitude]);
                        }
                        setInitialLoad(false);
                        setLoading(false)
                      }
    
                      setLoading(false);
                      console.log("Position updated:", newPosition);
                    } else {
                      console.warn("Position out of bounds.");
                      setActiveGPS(false);
                      handleOutOfBounds();
                      setLoading(false);
                    }
                  },
                  // error handling function
                  (error) => {
                    if (error.code === error.TIMEOUT) {
                      console.warn("Position acquisition timed out. Retrying...");
                      setTimeout(() => updateUserPosition(), 5000); // Retry after 5 seconds
                    } else {
                      console.error("Geolocation error:", error.message);
                      setAccessError(true);
                      setLoading(false);
                      setActiveGPS(false)
                    }
                  },
                  //function options
                  {
                    enableHighAccuracy: true,
                    maximumAge: 5000, // Optional: No cached positions
                    timeout: 10000, // Optional: Timeout for initial acquisition
                  },
                );
              } else {
                console.warn("Geolocation is not available in your browser.");
                setLoading(false);
                setActiveGPS(false)
              }
            }
          } else {
            console.warn("Permissions API is not supported in this browser.");
            setLoading(false);
            setActiveGPS(false)
          }
        } catch (error) {
          console.error("Error during geolocation process:", error);
          setLoading(false);
          setActiveGPS(false)
        }
      };
    
      updateUserPosition();
      return () => {
        // Cleanup function to stop watching the position when the component unmounts or dependencies change
        if (watchID !== undefined) {
          navigator.geolocation.clearWatch(watchID);
        }
      }
    
    }, [activeGPS, userPosition, initialLoad]);

    return (
      <Stack direction='row'
      //onMouseEnter={handleMouseEnter} 
      //onMouseLeave={handleMouseLeave}
      >
        <Tooltip title={activeGPS? 'Turn Off Location': 'Find My Location'} variant='solid' placement='right'>
  
        <Button loading={loading? true:false} variant='solid'
          sx={{
            position: 'fixed', 
            top: '60px', 
            left: '10px', 
            zIndex: 1006, 
            width: '30px', 
            height: '30px', 
            marginTop: '10px', 
            border: '1px solid grey',
            backgroundColor: 'white', 
            '&:hover': { 
              backgroundColor: 'var(--grey-hover)' 
            }
          }} 
        onClick={handleGPSActivate}>
      {/*activeGPS? 'deactivate GPS': 'ActivateGPS'*/}
      <img style={{width:'20px', height:'18px'}} src={activeGPS ? redLocation : off }/>
      </Button>
            </Tooltip>
            <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'left',}}
            open={accessError}
            size="sm"
            autoHideDuration={5000}
            onClose={handleSnackClose}
            sx={{marginLeft:'50px', marginTop:'60px'}}
            >
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <img src={alert} alt='' style={{height:'20px'}} />
                <Typography level={'body-xs'}>
                Location Access Denied, Please Allow and Retry
                </Typography>
              </Stack>
            </Snackbar>
      </Stack>
  
    );
  }

  const TogglePinList = ({ setDisableZoom }) => {
    const { pinListPositions, focusedPin, setFocusedPin, pinView, setPinView } = usePinListPosition();
    const {currentUserRoles, activeIcon} = usePosition();

    const map = useMap();
  
    const handleModalOpen = (index) => {
      //console.log('Marker clicked at index:', index);
      setFocusedPin(index);
    };
  
    useEffect(() => {
      if (focusedPin != null) {
        setPinView(true)
        handleModalOpen(focusedPin)
        //console.log(focusedPin)
        //console.log(pinListPositions)
        //console.log(pinListPositions[focusedPin])
        
        map.panTo([parseFloat(pinListPositions[focusedPin]?.latitude),parseFloat(pinListPositions[focusedPin]?.longitude)] )
      }
        
      }, [focusedPin]);

      useEffect(()=> {
        setFocusedPin(null)
      },[pinListPositions])
  
    return (
      <>
        {pinListPositions.length > 0 && pinView &&
          pinListPositions.map((inputPin, index) => (
            <React.Fragment key={index}>
              
              <Marker
                position={[inputPin.latitude, inputPin.longitude]}
                zIndex={1010}
                icon={L.icon({
                  iconUrl: focusedPin===index?cyanLocation:orangeLocation,
                  iconSize: focusedPin===index?[30, 30]:[25, 25],
                  iconAnchor: focusedPin===index?[15, 30]:[12, 25],
                })}
                eventHandlers={{
                  click: (e) => {
                    handleModalOpen(index)
                  },
                }}
                >
              <TooltipLeaflet offset={[-10, -20]} direction='left'>
              {currentUserRoles?.includes('Admin')?
              <Stack >
                <Typography level='body-xs'>
                  {inputPin.title}
                </Typography>
                <Typography level='body-xs'>
                  - {inputPin.creator}
                </Typography>
                
              </Stack>
                 :
                 <Typography level='body-xs'>{inputPin.title}</Typography>}
              </TooltipLeaflet>
                </Marker>
                
              {focusedPin === index && (
                <PinSummaryModal 
                  inputPin={inputPin} 
                  setDisableZoom={setDisableZoom} 
                  onClose={() => setFocusedPin(null)} // Define close function here
                />
              )}
            </React.Fragment>
          ))}
      </>
    );
  };




  export {CenterOnPositionFunction, CenterOnUserPositionFunction, GPSLocatorComponent,TogglePinList, MapControl}