import React, {useState, useEffect} from 'react';

import { useNavigate } from 'react-router-dom';
import { isMobile, isBrowser, BrowserView } from 'react-device-detect';
import { CSSTransition } from 'react-transition-group';

import { handleSignOut } from '../utils/SignOut';
import PinListDisplay from '../utils/navigationUtils/pinSelectionList';
import { usePosition } from '../utils/MapUtils/MapContext/PositionContext';
import { usePinListPosition } from '../utils/MapUtils/MapContext/PinListContext';
import { SupportForm } from '../utils/Modals/SupportToggle';
import { AboutULLRPageModal, SupportULLRPageModal, GetInTouchULLRPageModal } from '../utils/Modals/ULLRPageModals';

import { ToggleButtonGroup, Typography, Snackbar, Box, Stack, Button, Drawer, List, ListItem, ListItemButton, ListItemDecorator, Divider, ModalClose, AccordionGroup,    } from '@mui/joy';
import { Accordion, AccordionSummary, AccordionDetails, Card, Grid, Modal } from '@mui/joy';
import shsm from '../assets/navigation/sh-sm.png'
import shlg from '../assets/navigation/sh-lg.png'
import wbsm from '../assets/navigation/wb-sm.png'
import wblg from '../assets/navigation/wb-lg.png'

import fbBlack from '../assets/navigation/social/fbblack.png'
import fbColor from '../assets/navigation/social/fbcolor.png'
import igBlack from '../assets/navigation/social/igblack.png'
import igColor from '../assets/navigation/social/igcolor.png'

import { NavImages } from '../assets/navigation/navImgs';
import largeLogo from '../assets/ULLRLogo.svg'
import smallLogo from '../assets/ULLRLogo-small.svg'
import { BasicAssets } from '../assets/basicAssets';

import '../css/transitions.css'
import '../css/navbar.css'
import '../App.css'

export default function Navbar( { handleSetCurrentMap, currentMap, setCurrentMap} ) {
    const [leftOpen, setLeftOpen ] = useState(false);
    const [openFA, setOpenFA ] = useState(false);
    const [faError, setFAError] = useState(false)
    const [mapOpen, setMapOpen ] = useState(false)
    const [aboutModalOpen, setAboutModalOpen] = useState(false)
    const [supportPageModalOpen, setSupportPageModalOpen] = useState(false)
    const [getInTouchPageModalOpen, setGetInTouchPageModalOpen] = useState(false)
    //const [pinOpen, setPinOpen ] = useState(false)
    const {userPosition, setUserPosition, pinListOpen, setPinListOpen, currentUserRoles, userID, activeIcon, setActiveIcon} = usePosition()
    const {setPinView} = usePinListPosition();
    const [loading, setLoading] = useState(false)
    const [result, setResult ] = useState("");

    // about modal opening
    const handleAboutModalClose = () => {
        setAboutModalOpen(false)
    }
    const handleAboutModalOpen =() => {
        setAboutModalOpen(true)
        setSupportPageModalOpen(false)
        setExpanded(false)
        setLeftOpen(false)
        setOpenFA(false)
        setMapOpen(false)
        setPinListOpen(false)
        setGetInTouchPageModalOpen(false)
        setActiveIcon({
            search:false,
            newPin:false,
            getInTouch:false,
        })
    }

        // support page modal opening
        const handleSupportModalClose = () => {
            setSupportPageModalOpen(false)
        }
        const handleSupportModalOpen =() => {
            setSupportPageModalOpen(true)
            setAboutModalOpen(false)
            setExpanded(false)
            setLeftOpen(false)
            setOpenFA(false)
            setMapOpen(false)
            setPinListOpen(false)
            setGetInTouchPageModalOpen(false)
            setActiveIcon({
                search:false,
                newPin:false,
                getInTouch:false,
            })
        }

    /// handling support form access from navbar
    const handleGetInTouchOpen = () => {
        setExpanded(false)
        setLeftOpen(false)
        setOpenFA(false)
        setMapOpen(false)
        setPinListOpen(false)
        setAboutModalOpen(false)
        setSupportPageModalOpen(false)
        setGetInTouchPageModalOpen(true)
        setActiveIcon({
            search:false,
            newPin:false,
            getInTouch:true,
        })
    }

    const handleGetInTouchModalClose = () => {
        setGetInTouchPageModalOpen(false)
        setResult('')
    }

    const onGetInTouchSubmit = async (event) => {
      event.preventDefault();
      setResult("Sending....");
      const formData = new FormData(event.target);
  
      formData.append("access_key", "bcaf3925-5598-40a8-afeb-8a59aee7c7c0");
  
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData
      });
  
      const data = await response.json();
  
      if (data.success) {
        setResult("Form Submitted Successfully!");
        setTimeout(() => {
          event.target.reset();
          setGetInTouchPageModalOpen(false); // Initiates exit transition
          setTimeout(() => handleGetInTouchModalClose(), 1000); // Close after 1 second fade-out
        }, 3000);
      } else {
        console.log("Error", data);
        setResult(data.message);
      }
    };

    useEffect(()=> {
        if(activeIcon.search) {
            setPinListOpen(false)
        }
        if(activeIcon.getInTouch) {
            setGetInTouchPageModalOpen(true)
        }
        if(!activeIcon.getInTouch) {
            setGetInTouchPageModalOpen(false)
        }
    }, [activeIcon])

    const returnToMainNav = () => {
        setLeftOpen(true)
        setOpenFA(false)
        setMapOpen(false)
        setPinListOpen(false)
        setAboutModalOpen(false)
        setSupportPageModalOpen(false)
        setGetInTouchPageModalOpen(false)
    }
    
    const handleNavChange = (map) => {

        handleSetCurrentMap(map)
        setExpanded(false)
        setLeftOpen(false)
        setOpenFA(false)
        setMapOpen(false)
        setPinListOpen(false)
        setAboutModalOpen(false)
        setGetInTouchPageModalOpen(false)
    }
    const handleMapLibraryNav = () => {
        setLeftOpen(false)
        setOpenFA(false)
        setMapOpen(false)
        setPinListOpen(false)
        setAboutModalOpen(false)
        setGetInTouchPageModalOpen(false)

    }

    const handlePinListNav = () => {
        setLeftOpen(false)
        setOpenFA(false)
        setMapOpen(false)
        setPinListOpen(false)
        setAboutModalOpen(false)
        setGetInTouchPageModalOpen(false)
        setActiveIcon({
            search:false,
            newPin:false,
            getInTouch:false,
        })
        if (isBrowser) {
            setPinView(false)
        }
    }

    const handlePinListOpen =()=>{
        isMobile
        ?
        setPinListOpen(true)
        :
        setLeftOpen(false)
        setOpenFA(false)
        setMapOpen(false)
        setPinListOpen(true)
        setGetInTouchPageModalOpen(false)
    }


    ///// social media logic for bottom of navbar
    const [imgSrcIG, setImgSrcIG] = useState(igBlack);
    const [imgSrcFB, setImgSrcFB] = useState(fbBlack);

    const handleMouseEnterIG = () => {
        setImgSrcIG(igColor); // Image source when hovered
    };
    const handleMouseLeaveIG = () => {
        setImgSrcIG(igBlack); // Revert to default image
    };
    const handleExternalNavigationIG = () => {
        window.open("https://www.instagram.com/ullrmaps/", "_blank", "noopener,noreferrer");
    };

    const handleMouseEnterFB = () => {
        setImgSrcFB(fbColor); // Image source when hovered
    };
    const handleMouseLeaveFB = () => {
        setImgSrcFB(fbBlack); // Revert to default image
    };
    const handleExternalNavigationFB = () => {
        window.open("https://www.facebook.com/ULLRMaps", "_blank", "noopener,noreferrer");
    };

    const updateUserPosition = async () => {
        if ("permissions" in navigator) {
            const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
            
            if (permissionStatus.state === "denied") {
                // If location access is denied, inform the user to enable location in settings
                console.log("Location permission is denied. Please enable location access in your browser settings.");
                setFAError(true);
                setLoading(false);
                return;
            }
            
            if (permissionStatus.state === "prompt" || permissionStatus.state === "granted") {
                // If the permission is not denied, request location
                if ("geolocation" in navigator) {
                    setLoading(true); // Show loading while requesting location
                    
                    navigator.geolocation.getCurrentPosition(function (position) {
                        const newPosition = {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                            elevation: position.coords.altitude,
                        };
    
                        setUserPosition(newPosition);  // Update user position
                        setLoading(false);             // Hide loading
                    }, function (error) {
                        // Handle geolocation errors (e.g., if the user denies the permission in the popup)
                        console.error("Geolocation error:", error.message);
                        setFAError(true);
                        setLoading(false);
                    });
                } else {
                    console.log("Geolocation is not available in your browser.");
                    setLoading(false);
                }
            }
        } else {
            console.log("Permissions API is not supported in this browser.");
            setLoading(false);
        }
    };

    const retryUpdateUserPosition = async() => {
        setLoading(true)
        if ("permissions" in navigator) {
            const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
            
            if (permissionStatus.state === "denied") {
                // If location access is denied, inform the user to enable location in settings
                console.log("Location permission is denied. Please enable location access in your browser settings.");
                setFAError(true);
                setLoading(false);
                return;
            }
            
            if (permissionStatus.state === "prompt" || permissionStatus.state === "granted") {
                // If the permission is not denied, request location
                if ("geolocation" in navigator) {
                    setLoading(true); // Show loading while requesting location
                    
                    navigator.geolocation.getCurrentPosition(function (position) {
                        const newPosition = {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                            elevation: position.coords.altitude,
                        };
    
                        setUserPosition(newPosition);  // Update user position
                        setLoading(false);             // Hide loading
                    }, function (error) {
                        // Handle geolocation errors (e.g., if the user denies the permission in the popup)
                        console.error("Geolocation error:", error.message);
                        setFAError(true);
                        setLoading(false);
                    });
                } else {
                    console.log("Geolocation is not available in your browser.");
                    setLoading(false);
                }
            }
        } else {
            console.log("Permissions API is not supported in this browser.");
            setLoading(false);
        }
    };

    const [copied, setCopied] = useState(false);

    const phoneNumber = "604-935-5555";

    const handleCopy = () => {
        const textToCopy = "604-935-5555";
        
        // Use the clipboard API to copy the text
        navigator.clipboard.writeText(textToCopy)
          .then(() => {
            setCopied(true); // Optional: Set copied state to true if successful
            setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
          })
          .catch(err => {
            console.error('Failed to copy text: ', err);
          });
      };
      const handleCoordinates = () => {
        
        console.log(userPosition)
        const textCopy = userPosition.latitude.toString() + ', ' + userPosition.longitude.toString()
        // Use the clipboard API to copy the text
        navigator.clipboard.writeText(textCopy)
          .then(() => {
            setCopied(true); // Optional: Set copied state to true if successful
            setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
          })
          .catch(err => {
            console.error('Failed to copy text: ', err);
          });
      };

////// below is the map navbar control info
      const [expanded, setExpanded] = useState(false);

      const handleAccordionChange = (panel) => (event, isExpanded) => {
          setExpanded(isExpanded ? panel : false);
      };
        // Placeholder data for each map entry's additional information
        const mapData = {
            wb512300: {
                mapName:'Whistler Blackcomb (Lo-Res)',
                map:'wb512300',
                version: '2024',
                lastUpdated: 'Nov 21, 2024',
                projection: 'UTM Zone 10N',
                datum: 'NAD83',
                contourInterval: '10m',
                scale: '1:3,937',
                img:wblg,
            },
            wb1024450: {
                mapName:'Whistler Blackcomb (High-Res)',
                map:'wb1024450',
                version: '2024',
                lastUpdated: 'Nov 21, 2024',
                projection: 'UTM Zone 10N',
                datum: 'NAD83',
                contourInterval: '10m',
                scale: '1:3,937',
                img:wblg,
            },
            spearhead: {
                mapName:'Spearhead Traverse',
                map:'spearhead',
                version: '2.0',
                lastUpdated: '2020',
                projection: 'UTM Zone 10N',
                datum: 'NAD83',
                contourInterval: '20m',
                scale: '1:10,597',
                img:shlg,
            }
        };



  return (
    <Box sx={{ display: 'flex', 

        }}>
        <Box width='100%' height='60px' 
                sx={{
                    boxShadow: '0px 5px 8px 0px rgba(64, 64, 64, 1)', // Adding drop shadow
                    backgroundColor: 'white', // Ensure background color for visibility
                    position: 'relative', // Ensure position is set
                    zIndex: 1000, // Make sure it's on top of other elements,
                }}
        >
            <Stack direction='row' justifyContent='space-between' alignItems='center'>

                <Button variant="outlined" color="neutral"
                    style={{
                        width:'30px',
                        height:'30px',
                        marginLeft:'10px',
                        }}
                    onClick={() => setLeftOpen(true)}>
                    <img src={BasicAssets.hamburger} alt="hamburger menu" style={{ width:'15px'}}/>
                </Button>
                <img className='largeLogo' src={largeLogo} alt='ULLR Logo' onClick={()=>handleNavChange('wbcombo')}/>

                <Button variant={openFA?'soft':"outlined"} color={openFA?'danger':"neutral"}
                    style={{
                        width:'30px',
                        height:'30px',
                        marginRight:'10px',
                        }}
                        onClick={() => setOpenFA(!openFA)}>
                    <img src={BasicAssets.firstaid} alt="location menu" style={{ width:'15px'}}/>
                </Button>

            </Stack>
        </Box>    

      <Drawer 
            slotProps={{
                content: { sx: { width: isMobile? mapOpen?'350px':'275px':mapOpen?'625px':'275px' } }, // Custom width for the drawer paper
              }}
      open={leftOpen} anchor='left' onClose={() => setLeftOpen(false)}>
            <Stack height='100%' justifyContent='space-between'>
            <List size='lg' 
            sx={(theme) => ({

                // override global variant tokens
                '--joy-palette-neutral-plainHoverBg': 'var(--grey-hover)',
                '--joy-palette-neutral-plainActiveBg': 'rgba(229, 229, 230  )',

              })}
            >
                <Stack alignContent={'left'} sx={{marginLeft:'8px', marginBottom:'4px'}}>
                    <Box py={0.5}>
                        <Stack direction='row' spacing={1} alignItems='center' >
                        <img className='smallLogo' src={smallLogo} alt='ULLR Logo' onClick = {()=>handleNavChange('wbcombo')}/>
                        <Typography fontSize={17} fontWeight='bold' sx={{lineHeight:'1.2', height:'auto', width:'200px'}}>
                        {
                        currentMap==='wbcombo' ?'Whistler Blackcomb (Low-Res)':
                        currentMap==='spearhead'  ?'Spearhead':
                        currentMap==='whistler' ?'Whistler':
                        currentMap==='wb512300' ?'Whistler Blackcomb (Low-Res)':
                        currentMap==='wb512450' ?'Whistler Blackcomb (Low-Res)':
                        currentMap==='wb1024300' ?'wb1024300':
                        currentMap==='wb1024450' ?'Whistler Blackcomb (High-Res)':
                        currentMap==='Admin' ?'Admin':
                        //currentMap==='About' ?'About ULLR Maps':
                        currentMap==='Support ULLR' ?'Support ULLR':
                        currentMap==='Purchase Prints' ?'Purchase Prints':
                        currentMap==='blankmap' ?'': ''
                        }
                        </Typography>

                        </Stack>
                    </Box>
                    <ModalClose size='lg' />


                </Stack>
                <Divider />

            <ListItem key={'Map Library'} sx={{marginTop:'4px'}}>
                <ListItemButton onClick={() => setMapOpen(true)}>
                <ListItemDecorator ><img className='navbarDecorator' src={NavImages.maps}/></ListItemDecorator>
                <Typography fontSize={17} fontWeight='bold'>Map Library</Typography>
                <img src={mapOpen?NavImages.leftChevron:NavImages.rightChevron} style={{position:'fixed',right:'18px',height:'15px'}}/>
                    </ListItemButton>
                    {mapOpen &&
                        <Box
                            size="sm"
                            sx={{
                                ml: isMobile?0:'275px', 
                                position: 'fixed', // Makes the box fixed
                                zIndex: 1010, // Sets the z-index
                                height: '100vh', // Full viewport height
                                width: '350px', // Fixed width
                                top: 0, // Align to the top of the viewport
                                left: 0, // Align to the left of the viewport
                                backgroundColor: 'white', // Optional: set a background color
                                boxShadow: 3, // Optional: adds a shadow for better visibility
                                borderLeft: !isMobile?'1px solid grey':'',
                            }}
                            open={mapOpen}
                            anchor="left"
                            onClose={handleMapLibraryNav}
>

                        <Stack alignContent={'left'} sx={{marginLeft:'8px'}}>
                            <Box py={0.5}>
                            <Stack direction='row' spacing={2} alignItems='center'>
                                <img className='smallReturn' src={BasicAssets.leftArrow} alt='return' onClick={returnToMainNav}/>
                                <Typography fontSize={22} fontWeight='bold' >Map Library</Typography>

                            </Stack>
                            </Box>
                            <ModalClose size='sm' />
                        </Stack>
                        <Divider />
                        <AccordionGroup sx={{ width: '100%',}}>
        <Stack sx={{  paddingTop: '8px',  }}>

            {Object.entries(mapData).map(([key, data]) => (
                <React.Fragment key={key}>
                <Accordion
                    key={key}
                    expanded={expanded === key}
                    onChange={handleAccordionChange(key)}
                >
                <AccordionSummary >
                        <Stack  direction='column' sx={{padding:'4px'}} >

                        <Typography fontSize={17} fontWeight='bold' >{data.mapName}</Typography>
                        
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack >
                            <Divider width='90%'/>
                            <Box
                sx={{
                    width: '115%',
                    height: '200px',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: '-7.5%',
                    marginRight: '-7.5%',
                }}
            >
                <img
                    src={data.img}
                    alt='map thumbnail'
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                    }}
                />
            </Box>
                        <Stack direction={'row'} spacing={3} px={1} py={1}>
                            <Stack spacing={1} >
                        <Stack spacing={-0.5}>
                            <Typography level='body-sm' fontWeight={'bold'}>Version</Typography>
                            <Typography level='body-sm'> {data.version}</Typography>
                        </Stack>
                        <Stack spacing={-0.5}>
                            <Typography level='body-sm' fontWeight={'bold'}>Last Updated</Typography>
                            <Typography level='body-sm'> {data.lastUpdated}</Typography>
                        </Stack>
                        <Stack spacing={-0.5}>
                            <Typography level='body-sm' fontWeight={'bold'}>Projection</Typography>
                            <Typography level='body-sm'> {data.projection}</Typography>
                        </Stack>
                        </Stack>
                        <Stack spacing={1}>
                        <Stack spacing={-0.5}>
                            <Typography level='body-sm' fontWeight={'bold'}>Datum</Typography>
                            <Typography level='body-sm'> {data.datum}</Typography>
                        </Stack>
                        <Stack spacing={-0.5}>
                            <Typography level='body-sm' fontWeight={'bold'}>Contour Interval</Typography>
                            <Typography level='body-sm'> {data.contourInterval}</Typography>
                        </Stack>
                        <Stack spacing={-0.5}>
                            <Typography level='body-sm' fontWeight={'bold'}>Scale</Typography>
                            <Typography level='body-sm'>{data.scale}</Typography>
                    </Stack>
                    </Stack>
                    </Stack>
                    <Button  variant='outlined' color='neutral' onClick={()=>handleNavChange(data.map)}>View</Button>
                            </Stack>
                    </AccordionDetails>
                            
                </Accordion>
                <Divider/>
                </React.Fragment>
            ))}
        </Stack>
        </AccordionGroup>

                    </Box>
                    }
            </ListItem>

                {(currentMap !== 'Admin' && 
                    currentMap !== 'About' && 
                    currentMap !== 'Support ULLR' && 
                    currentMap !== 'Purchase Prints' && 
                    currentMap !== '') &&
                                <ListItem key={'Saved Pins'}>
                                    <ListItemButton onClick={handlePinListOpen}>
                                    <ListItemDecorator ><img className='navbarDecorator' src={NavImages.pin}/></ListItemDecorator>
                                    <Typography fontSize={17} fontWeight='bold'>Saved Pins</Typography>
                                    <img src={NavImages.rightChevron} style={{position:'fixed',right:'18px',height:'15px'}}/>
                                    </ListItemButton>

                    {isMobile &&
                    <Drawer 
                    size='md'
                    sx={{ ml:2 /*'min(100vw, var(--Drawer-horizontalSize))'*/ }}
                    open={pinListOpen}
                    anchor='left'
                    onClose={handlePinListNav}
                    slotProps={{
                        content: { sx: { width: '275px' } }, // Custom width for the drawer paper
                    }}
                    >


                        <Stack alignContent={'left'}
                        sx={{ 
                            position: 'sticky', 
                            top: 0, 
                            zIndex: 1, 
                            backgroundColor: 'white', // To avoid overlapping background issues
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                        <Box py={0.5} marginLeft={'8px'} >
                            <Stack direction='row' spacing={2} alignItems='center'>
                            <img className='smallReturn' src={BasicAssets.leftArrow} alt='return' onClick={returnToMainNav}/>
                                <Typography fontSize={22} fontWeight='bold'>Saved Pins</Typography>
                            </Stack>
                        </Box>
                        <ModalClose size='sm' />
                        </Stack>
                        <Divider />
                        <PinListDisplay closeAll={handlePinListNav}/>

                    </Drawer>
                    }
                    </ListItem>
                    
                }
            <ListItem key={'About ULLR Maps'}>
                <ListItemButton onClick={handleAboutModalOpen}>
                <ListItemDecorator ><img className='navbarDecorator' src={NavImages.ullrLogo}/></ListItemDecorator>
                <Typography fontSize={17} fontWeight='bold'>About ULLR Maps</Typography>
                </ListItemButton>
            </ListItem>

            <ListItem key='Support ULLR'>
                <ListItemButton onClick={handleSupportModalOpen}>
                <ListItemDecorator ><img className='navbarDecorator' src={NavImages.support}/></ListItemDecorator>
                <Typography fontSize={17} fontWeight='bold'>Support ULLR</Typography></ListItemButton>
            </ListItem>


            <ListItem key='Get in Touch'>
                <ListItemButton onClick={handleGetInTouchOpen}>
                <ListItemDecorator ><img className='navbarDecorator' src={NavImages.contact}/></ListItemDecorator>
                <Typography fontSize={17} fontWeight='bold'>Get in Touch</Typography></ListItemButton>
            </ListItem>
            
            
        {currentUserRoles?.includes('Admin') &&
        
        <ListItem key={'AdminAccess'} sx={{marginTop:'25px'}}>
            <ListItemButton onClick={()=>handleNavChange('Admin')}><ListItemDecorator ><img className='navbarDecorator' src={NavImages.admin}/></ListItemDecorator>
            <Typography fontSize={17} fontWeight='bold'>Admin Access</Typography></ListItemButton>
        </ListItem>
        }
        </List>
<Stack direction='row' justifyContent='space-between' px={1}>

        <Button variant='outlined' color='neutral' sx={{width:'100px', margin:'5px'}} onClick={handleSignOut}>Sign Out</Button>

        <Stack direction='row' spacing={1}>
        <Button
        sx={{

            py: 0,
            px:1,      // Remove extra padding for a more compact button
            display: 'flex', // Use flex to align the emoji and text
            alignItems: 'center',
            justifyContent: 'center',
            margin: 0
        }}
        variant='plain'
        color='neutral'
        onMouseEnter={handleMouseEnterIG}
        onMouseLeave={handleMouseLeaveIG}
        onClick={handleExternalNavigationIG}
    >
        <img src={imgSrcIG} alt='instagram' style={{height:'25px'}} />
    </Button>
    <Button
        sx={{
            
            py: 0,
            px:1,      // Remove extra padding for a more compact button
            display: 'flex', // Use flex to align the emoji and text
            alignItems: 'center',
            justifyContent: 'center',
            margin: 0
        }}
        variant='plain'
        color='neutral'
        onMouseEnter={handleMouseEnterFB}
        onMouseLeave={handleMouseLeaveFB}
        onClick={handleExternalNavigationFB}
    >
        <img src={imgSrcFB} alt='facebook' style={{height:'25px'}} />
    </Button>
    </Stack>
        </Stack>
        </Stack>
      </Drawer>


{openFA
? 
<Card
sx={{
    position: 'fixed',
    top: '70px',
    right:  '10px',
    minWidth: 250,
    width: isMobile ? '40%' : '250px',
    bgcolor: 'white',
    borderRadius: 5,
    boxShadow: 3,
    backgroundColor: 'white',
    zIndex: 1008,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding:isMobile? 1:0,
    border: '1px solid grey',
    boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
    overflowY:'auto',
  }}
>
    <BrowserView>
    <Stack alignContent={'left'} 
    sx={{ 
        width:'248px',
        position: 'sticky', 
        top: 0, 
        zIndex: 1,
        paddingLeft:'15px', 
        backgroundColor: 'white', // To avoid overlapping background issues
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}>
    <Box py={1} >
        <Stack direction='row' spacing={2} alignItems='center'>
        {/*<img className='smallReturn' src={BasicAssets.rightArrow} alt='return' onClick={returnToMainNav}/>*/}
            <Typography level='h4' fontWeight='bold'>Ski Patrol</Typography>
        </Stack>
        
    </Box>
    <ModalClose size='sm' onClick={()=>setOpenFA(false)}  />
        <Divider />
    </Stack></BrowserView>
    <Stack spacing={1}>

        {userPosition.latitude !=null || userPosition.elevation != null 
        ?
<Stack alignItems='center'>
    <Stack direction='row' alignItems='center' p={0} m={-1}>
        <Button variant='plain' color='neutral'
            onClick={updateUserPosition}
            loading={loading} 
            sx={{width:'15px',height:'15px',p:0, marginRight:'7px'}}>
                <img src={BasicAssets.refresh} style={{height:'10px'}} alt='refresh'/>
        </Button>
        <Typography level='body-xs'>Current Coordinates:</Typography>
    </Stack>
    <Button onClick={handleCoordinates} variant='plain' color='neutral'>
        <Stack direction='row' spacing={1}>
            <Typography>{userPosition?.latitude.toFixed(4)}</Typography>
            <Typography>{userPosition?.longitude.toFixed(4)}</Typography>
        </Stack>
    </Button>
    {typeof userPosition.elevation === 'number' ?
    <Stack>
        <Typography>Approximate Elevation: {userPosition.elevation.toFixed(0)}m</Typography>
    </Stack>
    :
    <Stack>
    <Typography>Elevation unavailable</Typography>
</Stack>
    }

</Stack>
:
<>
{faError === false
?
    <Stack>
        <Button onClick={updateUserPosition} loading={loading} variant='plain' color='neutral'><Typography>{loading? '':'Find your position'}</Typography></Button>
    </Stack>
:
    <Stack>
        <Typography level='body-xs'>Location Access Denied, Please Allow & </Typography>
        <Button loading={loading} onClick={retryUpdateUserPosition} variant='plain' color='neutral'><Typography>{loading? '':'Retry'}</Typography></Button>
    </Stack>
}
        </>
}
<Stack alignItems='center' spacing={0}>

<Typography level='body-xs'>Whistler Blackcomb Ski Patrol #</Typography>
{isMobile
?
<a href={`tel:${phoneNumber}`}>
<Button variant='plain' color='neutral'>604-935-5555</Button>
</a>
:
<>
<Button onClick={handleCopy} variant='plain' color='neutral'>604-935-5555</Button>
</>
}
<Snackbar size='sm'open={copied} autoHideDuration={3000} anchorOrigin={{vertical:'top', horizontal:'right'}}
    sx={{maxWidth:'20px', marginTop:'70px', marginright:'10px'}}>Copied!</Snackbar>
    </Stack>
</Stack>
</Card>
:
<></>
}
{isBrowser && pinListOpen &&

<Box
  sx={{
    position: 'fixed', // Fix it to the screen
    top:'70px',
    left: 0,           // Align to the left of the screen
    width: '350px',    // Set the width to 200px
    height: activeIcon.newPin?'calc(100vh - 410px)':'calc(100vh - 110px)',
    //minHeight: activeIcon.newPin?'300px':'600px',   // Make the height fill the entire screen
    //maxHeight:activeIcon.newPin?'300px':'600px',
    zIndex: 1008,      // Ensure it's on top of other elements
    backgroundColor: 'white', // Optional: add a background color
    boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)', // Optional: slight shadow for better visibility
    overflowY: 'auto',
    marginLeft:'50px',
    borderRadius:'10px',
    border: '1px solid grey',
    
  }}
  open={pinListOpen}
  anchor="left"
  onClose={handlePinListNav}
>
    <Stack alignContent={'left'} 
    sx={{ 
        position: 'sticky', 
        top: 0, 
        zIndex: 1, 
        backgroundColor: 'white', // To avoid overlapping background issues
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}>
    <Box py={isMobile?0.5:1} marginLeft={'8px'}>
        <Stack direction='row' spacing={2} alignItems='center'>
        <img className='smallReturn' src={BasicAssets.leftArrow} alt='return' onClick={handlePinListNav}/>
            <Typography fontSize={17} fontWeight='bold'>Saved Pins</Typography>
        </Stack>
    </Box>
    <ModalClose size='sm' onClick={handlePinListNav}  />
    </Stack>
    <Divider />
    <PinListDisplay closeAll={handlePinListNav}/>

            </Box>
}
{aboutModalOpen &&
<AboutULLRPageModal open={aboutModalOpen} handleClose={handleAboutModalClose} returnToMainNav={returnToMainNav} />
}
{supportPageModalOpen &&
<SupportULLRPageModal open={supportPageModalOpen} handleClose={handleSupportModalClose} returnToMainNav={returnToMainNav} />
}

{getInTouchPageModalOpen &&
<GetInTouchULLRPageModal open={getInTouchPageModalOpen} handleClose={handleGetInTouchModalClose} returnToMainNav={returnToMainNav} />
}

{/*isMobile &&
      <CSSTransition in={supportOpen} timeout={1000} classNames="fade" unmountOnExit>
        <Modal open={supportOpen} onClose={handleSupportClose}>
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: isMobile?'95vw':'400px' }}>
            <ModalClose />
            <SupportForm userID={userID} onSubmit={onSupportSubmit} result={result} />
          </Box>
        </Modal>
        </CSSTransition>
        */}
        {/*isBrowser && supportOpen &&
          <Box 
          sx={{
            position: 'fixed', // Fix it to the screen
            top:'70px',
            left: '0px',           // Align to the left of the screen
            width: '450px',
            minHeight: '600px',    // Set the width to 200px
            maxHeight: 'calc(100vh - 110px)',
            //minHeight: activeIcon.newPin?'300px':'600px',   // Make the height fill the entire screen
            //maxHeight:activeIcon.newPin?'300px':'600px',
            zIndex: 1008,      // Ensure it's on top of other elements
            backgroundColor: 'white', // Optional: add a background color
            boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)', // Optional: slight shadow for better visibility
            overflowY: 'auto',
            marginLeft:'50px',
            borderRadius:'10px',
            border: '1px solid grey',
            
          }}
            open={supportOpen}
            onClose={handleSupportClose}>
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: isMobile?'95vw':'400px' }}>
            <ModalClose onClick={handleSupportClose} />
            <SupportForm userID={userID} onSubmit={onSupportSubmit} result={result} />
          </Box>
        </Box>
        */}
    </Box>
  );
}