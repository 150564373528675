import addLocation from './addLocation.png'
import alert from './alert.png'
import alert2 from './alert2.png'
import close from './close.png'
import cyanLocation from './cyan-location.png'
import firstaid from './firstaid.png'
import hamburger from './hamburger.png'
import leftArrow from './leftArrow.png'
import location from './location.png'
import orangeLocation from './orange-location.png'
import redLocation from './red-location.png'
import refresh from './refresh.png'
import rightArrow from './rightArrow.png'
import search from './search.png'
import conversation from './chat.png'
import questionMark from './question.png'


const BasicAssets = {
    addLocation:addLocation,
    alert:alert,
    alert2:alert2,
    close:close,
    cyanLocation:cyanLocation,
    firstaid:firstaid,
    hamburger:hamburger,
    leftArrow:leftArrow,
    location:location,
    orangeLocation:orangeLocation,
    redLocation:redLocation,
    refresh:refresh,
    rightArrow:rightArrow,
    search:search, 
    conversation:conversation,  
    questionMark:questionMark,
}

export {BasicAssets}